// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// s4n
import GeneralUtils from './../utils/common'
import { useIntl } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import Menu from "./s4n/Menu/menu"
import MenuUtils from "./../components/s4n/Menu/utils"
import LanguageSwitcher from './../components/languageSwitcher'
import Search from './../components/forms/Search';
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"

import LogoDark from './../images/custom/logo.svg'
import LogoBright from './../images/custom/logo-white-48.png'

// import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import withDrupalOauthConsumer from '../components/drupal-oauth/withDrupalOauthConsumer';
import SignIn from './s4n/AccountManagement/SignIn/SignIn';
import LogoutLink from './s4n/AccountManagement/LogoutLink/LogoutLink';

import PromotedFrontPage from './s4n/Promoted/FrontPage'
// import Accessibility from './s4n/Accessibility'

import Obfuscate from 'react-obfuscate';


// Shop START
import { connect } from 'react-redux'
import { cartFlyoutOpen } from './s4n/Shop/ext/actions'
const openFlyout = (dispatch) => dispatch(cartFlyoutOpen());
// Shop STOP

// ES6
//import Webform from 'hn-react-webform';



const Header = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { siteTitle } = props;



    // Shop START
    const {
      dispatch,
      // cart: { carts, itemCount },
      // cartFlyout: { open }
    } = props;
    // Shop STOP



    // console.log(props.intl.locale)
    // console.log(props.userAuthenticated)
    const sEmailAddress = `info@soft4net.io`;

    const injectContentToMenuId = {

      [`entity-node-16`]: <React.Fragment>
          <li className="dropdown-item go-to-parent-item">
            <Link to={16} prefix={t({ id: `soft4net_go_to_parent_item` })} className="nav-link"></Link>
          </li>
          <li className="dropdown-item">
            <a tabIndex="0" className="nav-link" href="tel:+48797567617">(+48) 797 567 617</a>
          </li>
          <li className="dropdown-item">
            {/* <a tabIndex="0" className="nav-link" data-encoded={GeneralUtils.rot13(sEmailAddress)} href="mailto:">
              <i className="far fa-envelope"></i>
              {GeneralUtils.rot13(sEmailAddress)}
            </a> */}
            <Obfuscate email={sEmailAddress} tabIndex="0" className="nav-link" />
          </li>
          
          {/* @todo: add social media */}
          <li className="dropdown-item">
            <a tabIndex="0" className="nav-link facebook" href="https://www.facebook.com/soft4net/" target="_blank" rel="noreferrer" title="Facebook"><i className="fab fa-facebook"></i> Facebook</a>
          </li>
          <li className="dropdown-item">
            <a tabIndex="0" className="nav-link instagram" href="https://www.instagram.com/soft4net/" target="_blank" rel="noreferrer" title="Facebook"><i className="fab fa-instagram"></i> Instagram</a>
          </li>
          {/* <li><a href="https://twitter.com/heuresis_pl" target="_blank" rel="noreferrer" className="twitter" title="Twitter"><i className="fab fa-twitter"></i></a></li> */}
          {/* <li><a href="https://www.linkedin.com/company/heuresis-certified-management-trainers-and-consultants-ltd./" target="_blank" rel="noreferrer" className="linkedin" title="LinkedIn"><i className="fab fa-linkedin"></i></a></li> */}

        {/* <div className="text px-3"></div> */}
      </React.Fragment>,

      [`entity-node-31`]: <React.Fragment>
        {/* <Menu type="footer" className="menu-footer" nodeLinkUriRoot="entity:node/14" /> */}
        {/* <Link to={14} className="btn btn-secondary" role="button"></Link> */}
        {/* <PromotedFrontPage limit={4} disableHeader={true} returnOnlyLink={true} anchorClass={`nav-link`} /> */}
        <li className="dropdown-item go-to-parent-item">
          <Link to={`/blog`} prefix={t({ id: `soft4net_go_to_parent_item` })} className="nav-link">Blog</Link>
        </li>
        <PromotedFrontPage limit={5} disableWrapp={true} CustomWrapper={props => <li className="dropdown-item">{props.children}</li>} returnOnlyLink={true} anchorClass={`nav-link`} />
      </React.Fragment>,
    }

    return (
      <React.Fragment>
        <header className="navigation fixed-top nav-bg">
          <nav className="navbar _navbar-expand-lg navbar-dark">

            <div className="row">
              <div className="col d-flex align-items-center justify-content-start">
                <Link to={`/`} title={`Soft4Net`} className="navbar-brand">
                  <img src={LogoBright} alt={siteTitle} className="logo bright" width="48" /> 
                  <img src={LogoDark} alt={siteTitle} className="logo dark" width="48" /> 
                  <span>Soft4Net</span>
                </Link>
              </div>
              
              <div className="col d-flex align-items-center justify-content-end">
                {/* <div className="col search">
                  <Search />
                </div> */}
                {/* <div className="accessibility _vertical">
                  <Accessibility />
                </div> */}
                <LanguageSwitcher currentMenuItemTranslations={MenuUtils.getCurrentMenuItemTranslations()} />

                {`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID && <div className="_col shopping-cart">
                  <a className="btn" onClick={() => openFlyout(dispatch)} title={ t({ id: `soft4net_shop_cart_flyout_header` }) }>
                    <i className="fas fa-shopping-cart"></i>
                  </a>
                </div>}

                {/* {props.userAuthenticated ? 
                    <>
                      <Button variant="outlined" component={Link} to="/user/profile">My Account</Button>
                      <Link to={`/user/profile`} title={`My Account`} className="___navbar-brand">My Account</Link>
                      <LogoutLink /> 
                    </>
                  : 
                    <SignIn />
                } */}

                <button tabIndex="0" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label={t({ id: `soft4net_toggle_navigation` })}>
                  {/* Bootstrap icons */}
                  {/* <span className="navbar-toggler-icon"></span> */}
                  {/* Font awesome icon */}
                  {/* <i className="fas fa-bars"></i> */}
                  {/* @see: https://icons8.com/icon/82853/menu */}
                  {/* <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 24 24" style={{fill: `#fff`}}>
                    <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z" />
                  </svg> */}
                </button>
              </div>

            </div>

              {/* <div className="collapse navbar-collapse text-center" id="navigation">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="/">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="about">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="blog">Blog</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="portfolio">Portfolio</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="contact">Contact</a>
                  </li>
                </ul>
              </div> */}

            <div className="collapse navbar-collapse" id="navigation">

              {/* <div className="container p-0"> */}

                <div className="container">
                  {/* <div className="row">
                    <div className="col">
                      <a href="tel:+48797567617"><i className="fas fa-phone-square-alt"></i> (+48) 797 567 617</a>
                      <a data-encoded={GeneralUtils.rot13(sEmailAddress)} href="mailto:">
                        <i className="fas fa-envelope"></i> {GeneralUtils.rot13(sEmailAddress)}
                      </a>
                      <Obfuscate email={sEmailAddress} tabIndex="0" className="nav-link" />
                    </div>
                    <div className="col accessibility">
                      <Accessibility />
                    </div>
                    <div className="col search">
                      <Search />
                    </div>
                    <div className="col language">
                      <LanguageSwitcher currentMenuItemTranslations={MenuUtils.getCurrentMenuItemTranslations()} />
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col">
                      <Menu type="main" injectContentToMenuId={injectContentToMenuId} />
                    </div>
                  </div>
                </div>



                {false && <div className="row">

                  {/* <div className="col">
                    <ul>
                      <li><a href="https://www.facebook.com/Heuresis1/" target="_blank" rel="noreferrer" className="facebook" title="Facebook"><i className="fab fa-facebook"></i></a></li>
                      <li><a href="https://twitter.com/heuresis_pl" target="_blank" rel="noreferrer" className="twitter" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://www.linkedin.com/company/heuresis-certified-management-trainers-and-consultants-ltd./" target="_blank" rel="noreferrer" className="linkedin" title="LinkedIn"><i className="fab fa-linkedin"></i></a></li>
                    </ul>
                  </div> */}

                  {`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID && <div className="col shopping-cart">
                    <a className="btn" onClick={() => openFlyout(dispatch)} title={ t({ id: "soft4net_shop_cart_flyout_header" }) }>
                      <i className="fas fa-shopping-cart"></i>
                      {/* {` `}
                      { t({ id: "soft4net_shop_cart_flyout_header" }) } */}
                    </a>
                  </div>}

                </div>}

                {/* <div className="row">
                  <div className="col">
                    <Menu type="main" enableLanguage={true} />
                  </div>
                </div> */}
                
              {/* </div> */}

              {/* <form className="form-inline my-2 my-lg-0">
                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                <button className="btn btn-outline-success my-2 my-sm-0" type="submit"><i className="fas fa-search"></i></button>
              </form> */}

            </div>

          </nav>
        </header>
      </React.Fragment>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}



// export default Header
const HeaderWrapped = withDrupalOauthConsumer(Header)



// Shop START
// const mapStateToProps = ({ cart, cartFlyout }) => ({ cart, cartFlyout });
// const mapStateToProps = state => ({ 
//   cart:       state.shopReducers.cart,
//   cartFlyout: state.shopReducers.cartFlyout,
// });
const mapStateToProps = state => {
/*
  console.log(state);
  { 
    appReducers: { isHighContrastMode: false },
    shopReducers: [Function: combination]
  }
*/
  return { 
    cart:       state.shopReducers.cart,
    cartFlyout: state.shopReducers.cartFlyout,
  }
};
export default connect(mapStateToProps)(HeaderWrapped);
// Shop STOP



// export default withStyles(styles)(withDrupalOauthConsumer(Navigation));