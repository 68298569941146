// @see: https://github.com/wiziple/gatsby-plugin-intl/blob/master/examples/gatsby-starter-default-intl/src/components/language.js
/*
import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
    pl: "Polski",
    en: "English",
    // ko: "한국어",
    de: "Deutsch",
}

const LanguageSwitcher = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: currentLocale === language ? `yellow` : `white`,
                margin: 10,
                textDecoration: `underline`,
                cursor: `pointer`,
              }}
            >
              {languageName[language]}
            </a>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default LanguageSwitcher
*/



/**
 * @see: https://raw.githubusercontent.com/krashnamusika/krashna-site/master/src/components/Header/LanguageSwitcher.js
 */
// import classNames from 'classnames'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { changeLocale, injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl'
// import MenuUtils from "./../components/s4n/Menu/utils"

// s4n
import FlagPL from 'flag-icon-css/flags/4x3/pl.svg'
import FlagEN from 'flag-icon-css/flags/4x3/gb.svg'
// import FlagDE from 'flag-icon-css/flags/4x3/de.svg'



// @todo: use env variable SOFT4NET_AVAILABLE_LANGUAGES=pl,en 
const languageNames = [
  { code: 'pl', label: 'Polski', flag: FlagPL },
  { code: 'en', label: 'English', flag: FlagEN },
  // { code: 'de', label: 'Deutsch', flag: FlagDE },
]

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props)

    // this.currentMenuItemTranslations = this.props.currentMenuItemTranslations || MenuUtils.getCurrentMenuItemTranslations();
    this.currentMenuItemTranslations = this.props.currentMenuItemTranslations;

    // console.log(this.currentMenuItemTranslations)
  }

  // getImageByCode(code) {
  //   return `/images/flags/24/${code.toUpperCase()}.png`;
  // }

  renderLanguageChoice(code, label, flag, currentLocale) {
    let activeClass = currentLocale === code ? 'active' : null;

    /**
     * We need to change to '/' if there's not translation available 
     * because if we set to null changeLocale() will just replace langcode in URL, that might go to unexisting route!!!
     */
    const currentMenuItemTranslationsRoute = this.currentMenuItemTranslations && this.currentMenuItemTranslations[code] ? this.currentMenuItemTranslations[code].link.url : '/';

    // console.log(currentMenuItemTranslationsRoute)

    return (
        <a tabIndex="0" href="#" onClick={() => changeLocale(code, currentMenuItemTranslationsRoute)} className={`dropdown-item ${activeClass ? activeClass : ``}`} key={code} hrefLang={code} title={label}>
          {/* <img src={this.getImageByCode(code)} alt={label} /> */}
          {/* <Img fluid={data.placeholderImage.childImageSharp.fluid} /> */}
          {/* <img src={flag} alt={label} style={{ border: '1px solid #ccc', maxWidth: 30 }} /> */}
          {/* <span className="label">{label}</span> */}
          <span className="icon" style={{backgroundImage: `url('${flag}')`}}></span>
          <span className="abbreviation">{code.toUpperCase()}</span>
          <span className="sr-only">{label}</span>
        </a>
    )
  }

  render() {
    return (
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => {
          const currentLanguage = languageNames.filter(({ code }) => currentLocale == code)[0]

          return (
            <React.Fragment>
              <Helmet
                defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
              >
                <html lang={currentLocale} />
              </Helmet>
              <div className="dropdown language">

                {/* <button className="btn dropdown-toggle" type="button" id="langDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={currentLanguage.label} data-currentlocale={currentLocale}>
                  <img src={this.getImageByCode(currentLocale)} alt="Language" />
                  <img src={currentLanguage.flag} alt={currentLanguage.label} style={{ border: '1px solid #ccc', maxWidth: 30 }} />
                  <span>{currentLocale.toUpperCase()}</span>
                  <span className="sr-only">{currentLanguage.label}</span>
                  <i className="fa fa-angle-down"></i>
                </button> */}

                <a tabIndex="0" id="langDropdown" className="btn dropdown-toggle" href="#" role="button" id="langDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={currentLanguage.label} data-currentlocale={currentLocale}>
                  <span className="icon" style={{backgroundImage: `url('${currentLanguage.flag}')`}}></span>
                  <span className="abbreviation">{currentLocale.toUpperCase()}</span>
                  <span className="sr-only">{currentLanguage.label}</span>
                </a>

                <div className="dropdown-menu" aria-labelledby="langDropdown">
                  {languageNames.map(l => {
                    // do not return current language on select list
                    if (currentLocale === l.code) {
                      return null;
                    }

                    return this.renderLanguageChoice(l.code, l.label, l.flag, currentLocale)
                  })}
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </IntlContextConsumer>
    )
  }
}

export default injectIntl(LanguageSwitcher)
