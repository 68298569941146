import React from 'react';
import Button from '@material-ui/core/Button';
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl } from "gatsby-plugin-intl"



const LogoutLink = (props) => {
  if (props.drupalOauthClient) {
    return(
        <Button
            variant="outlined"
            onClick={async () => {
                await props.drupalOauthClient.handleLogout();
                props.updateAuthenticatedUserState(false);
            }}
      >
        Log out
      </Button>
    );
  }

  return null
};

export default injectIntl(withDrupalOauthConsumer(LogoutLink))