import React from "react"
// import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
// import Link from "./Link"
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
// import MenuUtils from "./../s4n/Menu/utils"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
import Preloader from '../s4n/Preloader'
// import FormBasic from "./../forms/basic"
// import FormBasicMaterialUiExample1 from "./../forms/basic.material-ui.example-1"
// import FormBasicMaterialUiExample2 from "./../forms/basic.material-ui.example-2"
// import FormBasicMaterialUiExample3 from "./../forms/basic.material-ui.example-3"
// import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
// import FormContactS4n from "./../forms/webform/contact_s4n"
// import FormBasicMaterialUi1 from "./../forms/basic.material-ui.test"
// import FormFormikJsonSchema from "./../forms/formik-json-schema"
// import Harmonogram from './../../components/s4n/Harmonogram/harmonogram'
// import Shop from './Shop/index'

// import FormikJsonSchema from "./../forms/formik-json-schema"
// import ReactJsonSchema from "./../forms/react-jsonschema-form"
// import CircleInteractive from "./../s4n/CircleInteractive"

// import { Accordion, Card } from 'react-bootstrap' // TEST for src/assets/sass/fix/bootstrap/_transitions.scss

import Breadcrumbs from './Menu/Breadcrumbs'
import PromotedFrontPage from './../s4n/Promoted/FrontPage'
import GoogleMap from './../s4n/GoogleMap'
import FormContactS4n from './../forms/standalone/contact_s4n'



const ComponentFunctional = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children, name, nodeId, productId, hasFooterContactForm } = props;

    // console.log(props.location)
    // const path = globalHistory.location.pathname;

    // Loadable components START
    // let UseDrupalBlockContentBasic = null;
    let Subpages = null;
    let Portfolio = null;
    // let GoogleMap = null;
    // let Harmonogram = null;

    // let FormContactS4n = true === hasFooterContactForm ? loadable(() => import(`./../forms/standalone/contact_s4n`), {fallback: <Preloader />}) : null;

    switch (nodeId) {
        // case 1: // Home
        case 14: // Services
            Subpages = loadable(() => import(`./../s4n/Menu/Subpages`));
            break;
        case 16: // Contact
            // UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`), {fallback: <Preloader />})
            // GoogleMap = loadable(() => import(`./../s4n/GoogleMap`), {fallback: <Preloader />})
            break;
        case 17: // Portfolio
            Portfolio = loadable(() => import(`./../s4n/Portfolio`), {fallback: <Preloader />})
            break;
        // case 871:
        //     Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`), {fallback: <Preloader />})
        //     break;
    }
    // const FormContactS4n = loadable(() => import(`./../forms/webform/contact_s4n`))
    // const UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`))
    // const Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`))
    // Loadable components STOP
    
    return (
        <React.Fragment>
            {/* {(() => {
                return (
                    <React.Fragment>
                        This was rendered!
                    </React.Fragment>
                )
            })()} */}

            {/* !!! Children !!! */}
            {/* <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col"> */}

                        {nodeId !== Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) && 
                            <div className="container">
                                <Breadcrumbs nid={nodeId} />
                            </div>
                        }

                        { children }

                    {/* </div>
                </div>
            </div> */}

            {/* Harmonogram (Szkolenia) */}
            {/* {871 === nodeId && 
                <React.Fragment>
                    <Harmonogram />
                </React.Fragment>
            } */}

            {/* Services */}
            {/* {14 === nodeId && 
                <React.Fragment>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Subpages nid={nodeId} template={`service`} disableHeader={true} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            } */}

            {/* Portfolio */}
            {/* {17 === nodeId && 
                <React.Fragment>
                    <Portfolio />
                </React.Fragment>
            } */}

            {/* Contact form */}
            {hasFooterContactForm && 
                <React.Fragment>
                    {16 === nodeId && 
                        <section className="map">
                            {/* <h5 class="hide">Google Maps</h5> */}
                            <div className="container-fluid">
                                <div className="row">
                                    <GoogleMap />
                                </div>
                            </div>
                        </section>
                        
                    }
                    <section id="contact" className="section section-on-footer s4n-footer-contact" 
                        // data-background="images/backgrounds/bg-dots.png"
                    >
                        <div className="container">
                            <div className="row">
                                {16 !== nodeId && 
                                    <div className="col-12 text-center">
                                        <h2 className="section-title">{t({ id: `soft4net_footer_contact_title` })}</h2>
                                    </div>
                                }

                                <div className="col-lg-8 mx-auto">
                                    <div className="bg-white rounded text-center p-5 shadow-down w-100">
                                        <h4 className="mb-80">
                                            {16 === nodeId ? t({ id: `soft4net_footer_contact_form_title_alt` }) : t({ id: `soft4net_footer_contact_form_title` })}
                                        </h4>
                                        <FormContactS4n />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <div className="container-fluid p-0">
                        <div className="row no-gutters">
                            <div className="col">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.6138153517677!2d19.923515616158642!3d50.05606527942285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b7483d44267%3A0x8c3eb47731cb486c!2sHeuresis+Sp.+Z+O.o.!5e0!3m2!1sen!2spl!4v1561407705962!5m2!1sen!2spl" width="600" height="450" frameborder="0" style={{border: 0}} allowfullscreen></iframe>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-xs-12 col-md-6 order-md-2">
                        <div className="wp-block-soft4net-container-block container-fluid bg-orange mt-1 py-3">
                            <UseDrupalBlockContentBasic blockId="1" />
                        </div>
                    </div> */}
                        
                    {/* TEST for src/assets/sass/fix/bootstrap/_transitions.scss START */}
                    {/* <Accordion _defaultActiveKey={0}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={0}>
                                <strong>Formularz kontaktowy</strong>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={0}>
                                <Card.Body>
                                    <FormContactS4n />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <div style={{display: `none`}} onClick={(e) => e.target.style.display = 'block'}>
                        <FormContactS4n />
                    </div>
                    <div style={{visibility: `hidden`}} onClick={(e) => e.target.style.visibility = 'visible'}>
                        <FormContactS4n />
                    </div> */}
                    {/* TEST for src/assets/sass/fix/bootstrap/_transitions.scss STOP */}

                </React.Fragment>
            }

            {/* {MenuUtils.isCurrentUri('internal:/blog') && 
                <Blog />
            } */}

            {/* {[1062, 1083].includes(nodeId) && 
                <React.Fragment>
                    <Shop />
                </React.Fragment>
            } */}

            {/* {<Location>
                {({ location }) => {
                    // console.log(JSON.stringify(location, null, 4))
                    return (
                        // @see: https://github.com/gatsbyjs/gatsby/issues/8787
                        <div className="container">
                            { '/pl#contact' === location.pathname + location.hash && <FormBasic /> }
                            { '/pl#contact' === location.pathname + location.hash && <FormBasicMaterialUi /> }
                        </div>
                    )
                }}
            </Location>} */}

        </React.Fragment>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default ComponentFunctional