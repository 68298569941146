import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { cartFlyoutClose, cartRemove } from '../actions'
// import { Link } from 'react-router-dom'
// import { MdClose } from 'react-icons/md'
import { formatCurrency } from '../utils/currency'

// s4n
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { globalHistory } from "@reach/router"
// import Preloader from './../../../../s4n/Preloader'
import Product from './../../../../../model/commerce/product'



const closeFlyout = (dispatch) => dispatch(cartFlyoutClose());

const CartFlyout = (props) => {
  const {
    dispatch,
    cart: { cartToken, carts, itemCount },
    cartFlyout: { open }
  } = props;

  // console.log(cart) // === undefined, it seems this is how destructing works in nested arrays

  const cart = carts[0];

  // console.log(cart)

  // console.log(props.cartFlyout)

  // s4n
  const intl = useIntl();
  const t = intl.formatMessage;

  // const [state, setState] = useState({
  //   loading: false,
  //   error: false,
  // });
  // /**
  //  * @see: https://reactjs.org/docs/hooks-effect.html
  //  * Same as componentDidMount() hook because we pass an empty array [] as second argument,
  //  * this argument watches for changes in passed arguments
  //  * In componentDidMount we have access to window object elsewhere not always!!!
  //  */
  // useEffect(() => {
  //   setState({
  //     loading: false
  //   });
  // }, []);

  const resetCartToken = async (event) => {
    // return true;

    if (typeof window === `undefined`) {
      return false;
    }
    // event.preventDefault();

    try {
      localStorage.setItem('cartToken', Math.random().toString(36).substr(2));
    } catch(err) {
      console.log(err);
    }

    return true;
  }

  // const path = globalHistory.location.pathname;
  const pathAndHash = globalHistory.location.pathname + globalHistory.location.hash;
  let s4n_url_return = process.env.SOFT4NET_STATIC_BASE_URL + pathAndHash;



  let CheckoutLink = null;
  if (itemCount > 0) {
    switch (process.env.SOFT4NET_SHOP_CHECKOUT_TYPE) {
      case `iframe`:
        CheckoutLink = <Link 
            to={`/checkout/${cart.order_id}?cartToken=${cartToken}`} 
            className={`mx-2 btn btn-success`} 
            // onClick={event => resetCartToken(event)} 
          >{ t({ id: "soft4net_shop_cart_flyout_action_checkout" }) }</Link>
        break;
      case `offsite`:
        CheckoutLink = <a 
            href={`${process.env.REACT_APP_API_URL}/${intl.locale}/checkout/${cart.order_id}?cartToken=${cartToken}&s4n_url_return=${s4n_url_return}`} 
            className={`mx-2 btn btn-success`} 
            target="_blank"
            // onClick={event => resetCartToken(event)} 
          >{ t({ id: "soft4net_shop_cart_flyout_action_checkout" }) }</a>
        break;
      case `onsite`:
        // code block
        break;
      default:
        // code block
    }
  }



  let cartOrderItemsTotalPriceNumberTotalWithoutDiscounts = 0.0;

  return (
    <React.Fragment>
      <aside id="cart-offcanvas" className={`cart-offcanvas is-${open ? 'open' : 'closed'} cart-offcanvas--right`}>
        <div className="cart--cart-offcanvas well well-lg well-primary ___text-light">
          {/* <div className="cart--cart-offcanvas__close">
            <button type="button" onClick={() => closeFlyout(dispatch)} className="button btn btn-primary close-btn">
              <span className="visually-hidden"> { t({ id: "soft4net_shop_cart_flyout_close_cart" }) }</span>
            </button>
          </div> */}

            <div className={`cart-block--offcanvas-contents`} key={`contents`}>
              <div className={`cart-block--offcanvas-contents__inner`}>
                <h2>{ t({ id: "soft4net_shop_cart_flyout_header" }) }</h2>

                <div className={`cart-block--offcanvas-contents__items`}>

                  {itemCount === 0 ? [
                    <div key={`empty`} className="cart-empty mb-3">{ t({ id: "soft4net_shop_cart_flyout_empty_cart" }) }</div>
                  ] : [
                    <table className={`cart-block--offcanvas-cart-table table`}>
                      <tbody>
                      {cart.order_items.map(orderItem => {

                        // console.log(orderItem)
                        const drupal_internal__product_id = orderItem.purchased_entity.product_id;
                        const commerceProduct = Product.getItemByProductId(drupal_internal__product_id, intl.locale);
                        // console.log(commerceProduct)
                        // console.log(orderItem.total_price.number) // if 1200 x 2, so it shows 2400 here !!!
                        // cartOrderItemsTotalPriceNumberTotalWithoutDiscounts += Number(orderItem.total_price.number);
                        cartOrderItemsTotalPriceNumberTotalWithoutDiscounts += Number(orderItem.purchased_entity.resolved_price.number) * parseInt(orderItem.quantity);



                        return (
                          <tr key={orderItem.order_item_id}>
                            <td className="cart-block--offcanvas-cart-table__title align-middle w-50">
                              <Link 
                                to={commerceProduct ? commerceProduct.path.alias : undefined} 
                                className={`___text-light`}
                              >
                                {orderItem.title}
                              </Link>
                            </td>
                            <td className="cart-block--offcanvas-cart-table__quantity align-middle w-25">
                              <input className="form-control" type={`number`} min={0} defaultValue={parseInt(orderItem.quantity)} />
                            </td>
                            <td className="cart-block--offcanvas-cart-table__price align-middle ___text-light">
                              {/* {formatCurrency(orderItem.total_price.currency_code, orderItem.total_price.number)} */}
                              {orderItem.total_price.formatted}
                            </td>
                            <td className="cart-block--offcanvas-cart-table__remove align-middle">
                              <button className="btn text-danger" onClick={() => { dispatch(cartRemove(orderItem)) }}>
                                {/* <MdClose/> */}
                                <i className="fas fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      })}



                      {/* Cart summary START */}
                      {(() => {
                        const rabattTotal = cartOrderItemsTotalPriceNumberTotalWithoutDiscounts - Number(cart.total_price.number);
                        // console.log(cart)
                        // console.log(rabattTotal)

                        return (
                          <React.Fragment>
                            {/* Discounts */}
                            {rabattTotal > 0 &&
                              <tr key={`cart.total_price.discount`}>
                                <td className="cart-block--offcanvas-cart-table__title align-middle w-50">{t({ id: `Discount` })}</td>
                                <td className="cart-block--offcanvas-cart-table__quantity align-middle w-25">&nbsp;</td>
                                <td className="cart-block--offcanvas-cart-table__price align-middle ___text-light">
                                  {formatCurrency(cart.total_price.currency_code, -rabattTotal, intl.locale)}
                                </td>
                                <td className="cart-block--offcanvas-cart-table__remove align-middle">&nbsp;</td>
                              </tr>
                            }

                            {/* Totals */}
                            <tr key={`cart.total_price.total`}>
                              <td className="cart-block--offcanvas-cart-table__title align-middle w-50">{t({ id: `Total` })}</td>
                              <td className="cart-block--offcanvas-cart-table__quantity align-middle w-25">&nbsp;</td>
                              <td className="cart-block--offcanvas-cart-table__price align-middle ___text-light">
                                {/* {formatCurrency(cart.total_price.currency_code, cart.total_price.number)} */}
                                {cart.total_price.formatted}
                              </td>
                              <td className="cart-block--offcanvas-cart-table__remove align-middle">&nbsp;</td>
                            </tr>
                          </React.Fragment>
                        )

                      })()}
                      {/* Cart summary STOP */}



                      </tbody>
                      <tfoot>
                      <tr>
                        <td className={`text-right`} colSpan={4}>
                          <button type="submit" className="cart-block--offcanvas-contents__update btn btn-link ___text-light">
                            { t({ id: "soft4net_shop_cart_flyout_update_quantities" }) }
                          </button>
                        </td>
                      </tr>
                      </tfoot>
                    </table>
                  ]}
                </div>

                <div className="cart-block--offcanvas-contents__links _text-center d-flex justify-content-between">

                  <button type="button" onClick={() => closeFlyout(dispatch)} className="button btn btn-warning close-btn mx-2">
                    {/* <span className="visually-hidden"> */}
                      { t({ id: "soft4net_shop_cart_flyout_close_cart" }) }
                      {/* { t({ id: "soft4net_shop_cart_flyout_action_continue_shopping" }) } */}
                    {/* </span> */}
                  </button>

                  {/* <Link to="/cart" className={`btn ___text-light btn-link`}>View cart</Link> */}

                  {/* {state.loading ? // or state.loading this is a reference
                    <Preloader /> :  */}
                    {CheckoutLink}
                  {/* } */}

                </div>

                {/* <div className="cart--cart-offcanvas__close d-md-none text-center">
                  <button type="button" onClick={() => closeFlyout(dispatch)} className="btn ___text-light btn-link">{ t({ id: "soft4net_shop_cart_flyout_action_continue_shopping" }) }</button>
                </div> */}

              </div>
            </div>

        </div>
      </aside>
      <div id="cart-offcanvas-bg" className={`cart-offcanvas-bg is-${open ? 'open' : 'closed'}`} onClick={() => closeFlyout(dispatch)}/>
    </React.Fragment>
  )
}

// const mapStateToProps = ({ cart, cartFlyout }) => ({ cart, cartFlyout });
const mapStateToProps = state => {
  return {
    cart:       state.shopReducers.cart,
    cartFlyout: state.shopReducers.cartFlyout,
  }
};
export default connect(mapStateToProps)(CartFlyout);