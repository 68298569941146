// import _ from "lodash"
import moment from 'moment'
import 'moment/locale/pl'  // without this line it didn't work for PL!!!
// import { injectIntl } from "gatsby-plugin-intl"
import { globalHistory } from "@reach/router"



class Utils {
    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * @see: https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error
     * 
     * btoa() is like base64_encode()
     */
    static btoa = (str) => {
        const Buffer = Buffer || require(`buffer`).Buffer;

        if (typeof btoa === `undefined`) {
            return new Buffer(str, `binary`).toString(`base64`);
        }

        return btoa(str);
    }

    /**
     * @see: https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error
     * 
     * atob() is like base64_decode()
     */
    static atob = (base64Encoded) => {
        const Buffer = Buffer || require(`buffer`).Buffer;

        if (typeof atob === `undefined`) {
            return new Buffer(base64Encoded, `base64`).toString(`binary`);
        }

        return atob(base64Encoded);
    }

    /**
     * You can use btoa() and atob(). btoa() is like base64_encode() and atob() like base64_decode().
     */
    static getVersion = (version = undefined, time = undefined) => {
        return typeof version === `undefined` ? `?v=${new Date(time).valueOf()}` : version;
        // return typeof version === `undefined` ? `?v=${this.btoa(time)}` : version;
        //return Date.now() + Math.random();
    }

    // @see: https://momentjs.com/
    // @see: https://momentjs.com/docs/#/i18n/
    static dateFormat = (date, format = null, locale = null) => {
        !format && (format = "L")
        locale && moment.locale(locale)

        return moment(new Date(date)).format(format)
    }

    /**
     * @see: https://stackoverflow.com/questions/35352638/how-to-get-parameter-value-from-query-string
     * @see: https://stackoverflow.com/questions/747641/what-is-the-difference-between-decodeuricomponent-and-decodeuri
     */
    static getSearchParam = (param) => {
        let query = globalHistory.location.search.replace('?', '')
        let vars = query.split('&');
        // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (let i=0; i<vars.length; i++) {
            let pair = vars[i].split('=');
            // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] === param) {
                return decodeURIComponent(pair[1]);
            }
        }

        return decodeURIComponent('');
    }

    /**
     * https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
     */
    // static hexToRgbA = (hex) => {
    static hexToRgb = (hex) => {
        let c;

        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');

            // return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(',') + ',1)';
            return [(c>>16)&255, (c>>8)&255, c&255];
        }

        return [];
        // throw new Error('Bad Hex');
    }

    // @see: https://stackoverflow.com/questions/11233498/json-stringify-without-quotes-on-properties/11233515
    static stringify = (obj_from_json) => {
        if (typeof obj_from_json !== "object" || Array.isArray(obj_from_json)) {
            // not an object, stringify using native function
            return JSON.stringify(obj_from_json);
        }
        // Implements recursive object serialization according to JSON spec
        // but without quotes around the keys.
        let props = Object
            .keys(obj_from_json)
                .map(key => `${key}:${this.stringify(obj_from_json[key])}`)
                .join(",");

        return `{${props}}`;
    }

    static rot13 = (str) => {
        var input     = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var output    = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
        var index     = x => input.indexOf(x);
        var translate = x => index(x) > -1 ? output[index(x)] : x;

        return str.split('').map(translate).join('');
    }

    static getScreenSize = (windowInnerWidth = 0) => {
        // Bootsrap 4 default
        const bootstrapConfig = {
            breakpoint: {
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
            }
        }

        let screenSize = ``;

        if (windowInnerWidth < bootstrapConfig.breakpoint.sm) {
            screenSize = `xs`;
        } else if (bootstrapConfig.breakpoint.sm <= windowInnerWidth && windowInnerWidth < bootstrapConfig.breakpoint.md) {
            screenSize = `sm`;
        } else if (bootstrapConfig.breakpoint.md <= windowInnerWidth && windowInnerWidth < bootstrapConfig.breakpoint.lg) {
            screenSize = `md`;
        } else if (bootstrapConfig.breakpoint.lg <= windowInnerWidth && windowInnerWidth < bootstrapConfig.breakpoint.xl) {
            screenSize = `lg`;
        } else if (bootstrapConfig.breakpoint.xl <= windowInnerWidth) {
            screenSize = `xl`;
        }

        return `screen-` + screenSize;
    }

    static isBrowser = () => typeof window !== 'undefined'

    // @see: https://www.equalweb.com/
    static enableEqualwebAccessibility = (locale) => {
        if (!Utils.isBrowser() || typeof window.interdeal !== `undefined`) {
            return false;
        }

        // let INDWrap = document.getElementById(`INDWrap`);
        // INDWrap && INDWrap.remove();

        const EQUALWEB_ACCESSIBILITY_CODE_SETTINGS = {
            sitekey: {
                'soft4net.io': "915872daa8ef761a604c01bd1a0c43f0",
                'soft4net.pl': "e904e510a90789186a4a5372152cd9fa",
            },
            Menulang: {
                en: "EN-GB",
                pl: "PL",
            },
        }

        // <!-- Accessibility Code for "www.soft4net.io" -->
        // <script>
        let interdeal = {
            "sitekey": EQUALWEB_ACCESSIBILITY_CODE_SETTINGS.sitekey[process.env.GATSBY_SOFT4NET_DOMAIN],
            "Position": "Left",
            "Menulang": EQUALWEB_ACCESSIBILITY_CODE_SETTINGS.Menulang[locale],
            "domains": {
                "js": "https://cdn.equalweb.com/",
                "acc": "https://access.equalweb.com/"
            },
            "btnStyle": {
                "vPosition": [
                    "75%",
                    null
                ],
                "scale": [
                    "0.8",
                    "0.8"
                ],
                "icon": {
                    "type": 11,
                    "shape": "semicircle",
                    "outline": false
                },
                "color": {
                    "main": "#8a2c87",
                    "second": ""
                }
            }
        };

// console.log(`
//     window.interdeal.lang:  ${window?.interdeal?.lang}
//     locale: ${locale}
// `)

        // if (window?.interdeal?.lang === locale) {
        //     return false;
        // }

        window.interdeal = {...interdeal};
        // Object.defineProperty(window, `interdeal`, {
        //     configurable: true,
        //     enumerable: true,
        //     value: {...interdeal},
        //     writable: true,
        // });

        (function(doc, head, body){
            var coreCall             = doc.createElement('script');
            coreCall.src             = 'https://cdn.equalweb.com/core/3.0.0/accessibility.js';
            coreCall.defer           = true;
            coreCall.integrity       = 'sha512-Z/Fp/le5EMHTTNnZV1RBa6aoSrTkOjXLSdL6nHqG8c70vKlDKjeuUCs7l5Nxt66oSkEnlcZAX6gUUNqjhN9Hew==';
            coreCall.crossOrigin     = 'anonymous';
            coreCall.setAttribute('data-cfasync', true );
            body? body.appendChild(coreCall) : head.appendChild(coreCall);
        })(document, document.head, document.body);
        {/* </script> */}

        return true;
    }

}

export default Utils