import React from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl } from "gatsby-plugin-intl"



class SignIn extends React.Component {
    state = {
        open: false,
        processing: false,
        username: '',
        password: '',
        error: null,
    };

    constructor(props) {
        super(props)

        this.t = this.props.intl.formatMessage;
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleCancel = () => {
        this.setState({ open: false });
    }

    handleSubmit = async (event) => {
        // event.preventDefault();
        this.setState({ processing: true });
        const { username, password } = this.state;

        try {
            const response = await this.props.drupalOauthClient.handleLogin(username, password, '');
            // await this.props.drupalOauthClient.handleLogin(username, password, '');

            // console.log(response)

            if (typeof response === `undefined`) {
                throw new Error({
                    message: this.t({ id: "soft4net_form_login_form_error" }),
                });
            }

            // we were authenticated because no exception were thrown
            this.setState({ processing: false, open: false });
            this.props.updateAuthenticatedUserState(true);
        } catch (err) {

            // console.log(err)

            this.setState({
                processing: false,
                // error: err.message,
                error: {
                    message: this.t({ id: "soft4net_form_login_form_error" }),
                },
            });
        }
    }

    render() {
        return (
            <>
                <Button onClick={this.handleClickOpen} variant="contained" color="primary">Log in</Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">Log in</DialogTitle>
                <DialogContent>
                    {this.state.error && <p className="error">{this.state.error.message}</p>}
                    <DialogContentText>
                        { this.t({ id: "soft4net_form_login_form_title" }) }
                    </DialogContentText>
                    <TextField

                        error={this.state.error}
                        helperText={this.state.error && this.state.error.message}
                        
                        autoFocus
                        margin="dense"
                        name="username"
                        label={ this.t({ id: "soft4net_form_login_form_username" }) }
                        type="text"
                        fullWidth
                        onChange={event => this.setState({ [event.target.name]: event.target.value })}
                    />
                    <TextField

                        error={this.state.error}
                        helperText={this.state.error && this.state.error.message}

                        margin="dense"
                        name="password"
                        label={ this.t({ id: "soft4net_form_login_form_password" }) }
                        type="password"
                        fullWidth
                        onChange={event => this.setState({ [event.target.name]: event.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="default">{ this.t({ id: "soft4net_form_action_cancel" }) }</Button>
                    {this.state.processing ? <CircularProgress /> : <Button onClick={(event) => {this.handleSubmit(event)}} color="primary" variant="contained">Log in</Button>}
                </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default injectIntl(withDrupalOauthConsumer(SignIn))