/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'

import Header from "./header"
import Footer from "./footer"

// import "./layout.css"
// import cssModule from "./../assets/sass/components/Layout.module.scss"
// import "./../../node_modules/bootstrap/scss/bootstrap.scss";
import "./../assets/sass/index.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.css"
// import "./../assets/sass/HighContrast.module.css"

// s4n
// import $ from "jquery"
import { useIntl, navigate } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
// import Preloader from './s4n/Preloader'
import kebabCase from "lodash.kebabcase"
import classNames from 'classnames';
import S4nLayoutBlock from './s4n/layoutBlock'

// import loadScript from 'simple-load-script';


// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
import { withPrefix } from 'gatsby'
// import g_soft4NetFrontConfig from './../assets/js/s4n'
// import YupSetLocale from './../components/forms/yup-setlocale';

import drupalOauth from './drupal-oauth/drupalOauth';
import withDrupalOauthProvider from './drupal-oauth/withDrupalOauthProvider'

import { connect } from 'react-redux'

// SHOP EXT START
// // import React, { Component } from 'react';
// // import { ApolloProvider } from 'react-apollo';
// // import { Route, Switch } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router'
// import configureStore, { history } from './../components/s4n/Shop/ext/utils/configureStore';
// // import { graphqlClient } from './utils/api';

// // import Header from './blocks/Header';
// // import CatalogMenu from './blocks/CatalogMenu'
// // import Footer from './blocks/Footer'
// // import Home from './pages/Home';
// import Cart from './../components/s4n/Shop/ext/pages/Cart';
// // import Catalog from './pages/Catalog';
// // import Product from './pages/Product';
// import { cartFetch, setCartToken } from './../components/s4n/Shop/ext/actions'
// import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// // import Checkout from './pages/Checkout'

//     // @see: https://github.com/gatsbyjs/gatsby/issues/14480
//     /**
//      * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
//      * because render is called before useEffect()!
//      */
//     // const store = configureStore();
//     // // if (typeof window !== 'undefined') {
//     //   store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
//     //   // store.dispatch(setCartToken('ajg6t2g3kz'));
//     //   store.dispatch(cartFetch());
//     // // }
// SHOP EXT STOP

// s4n
import useVersion from './../hooks/useVersion'
import useScreenSize from './../hooks/useScreenSize'

import Preloader from './s4n/Preloader'
import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// import ShopCheckoutIframe from './../components/s4n/Shop/s4n/Checkout/Iframe'
import GeneralUtils from './../utils/common'



// process.env.NODE_ENV is set only in Express server not in running contexte from simple node invoke!!!
// @see: https://stackoverflow.com/questions/16978256/what-is-node-env-in-express
if (`development` === process.env.NODE_ENV) {
  // @see: https://github.com/formatjs/formatjs/issues/465#issuecomment-369566628 START
  // Disable missing translation message as translations will be added later.
  // We can add a toggle for this later when we have most translations.

  // eslint-disable-next-line
  const consoleError = console.error.bind(console);
  // eslint-disable-next-line
  console.error = (message, ...args) => {
    if (
      typeof message === 'string' && (
        message.startsWith('[React Intl] Missing message:') || 
        message.startsWith('[React Intl] Cannot format message:')
      )
    ) {
      return;
    }
    consoleError(message, ...args);
  };
  // @see: https://github.com/formatjs/formatjs/issues/465#issuecomment-369566628 STOP
}



const Layout = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children } = props;

    const [context, setContext] = useState({
      // loadedScripts: false,
      isHeroSlider: false,
      windowLoaded: false,
    });

    // SHOP EXT START
    // @see: https://github.com/gatsbyjs/gatsby/issues/14480
    /**
     * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
     * because render is called before useEffect()!
     */
    // const store = configureStore();
    // // if (typeof window !== 'undefined') {
    //   // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
    //   store.dispatch(setCartToken('9jg6t2g3kz'));
    //   store.dispatch(cartFetch());
    // // }
    // SHOP EXT STOP
   
    /**
     * Load 3rd party scripts async in order (local static assets mainly thanks to withPrefix)!!!
     * 
     * @see: https://stackoverflow.com/questions/34589488/es6-immediately-invoked-arrow-function
     * @see: https://stackoverflow.com/questions/40958680/react-router-how-to-trigger-document-ready
     */
    /*
      #!/bin/bash

      # create symlinks from sticky directory for local npm packages that you want to inject into body
      cd static/
      ln -s ../node_modules/jquery/dist/jquery.min.js jquery.min.js
      ln -s ../node_modules/jquery/dist/jquery.slim.min.js jquery.slim.min.js
      // ln -s ../node_modules/bootstrap/dist/js/bootstrap.min.js bootstrap.min.js
      ln -s ../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js bootstrap.bundle.min.js
      ln -s ../node_modules/owl.carousel/dist/owl.carousel.min.js owl.carousel.min.js
      ln -s ../node_modules/waypoints/lib/jquery.waypoints.min.js jquery.waypoints.min.js
      ln -s ../node_modules/waypoints/lib/shortcuts/sticky.min.js sticky.min.js
      ln -s ../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js jquery.magnific-popup.min.js
      ln -s ../node_modules/smooth-scroll/dist/smooth-scroll.polyfills.min.js smooth-scroll.polyfills.min.js
    */
    const loadScripts = async (nodeId, productId) => {
        callScripts(nodeId, productId);
    }

    const callScripts = (nodeId, productId) => {
        // GeneralUtils.enableEqualwebAccessibility(intl.locale);
    }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
      if (!GeneralUtils.isBrowser()) {
        return false;
      }

      // SHOP EXT START
      // @see: https://github.com/gatsbyjs/gatsby/issues/14480
      // const store = configureStore();
      // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
      // store.dispatch(cartFetch());
      // SHOP EXT STOP

      const { node } = props;
      let nodeId = null;
      let productId = null;

      if (node) {
        nodeId = node.drupal_internal__nid || null;
        productId = node.drupal_internal__product_id || null;
      }

      callScripts(nodeId, productId);

      // if (typeof window.g_soft4NetFrontConfig === `undefined`) {
      //   loadScripts(nodeId, productId);
      // } else {
      //   callScripts(nodeId, productId);
      // }
    }, []);

    // useEffect(() => {
    //   GeneralUtils.enableEqualwebAccessibility(intl.locale);
    // }, [
    //   // window.interdeal
    //   intl.locale
    // ]);

    // useLayoutEffect(() => {
    //   loadScripts();
    // })

    // @see: https://www.gatsbyjs.org/docs/use-static-query/
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    // const useDrupalBlockContentBasic_Soft4NetFooterContactDetails = useDrupalBlockContentBasic(1);
    // console.log(useDrupalBlockContentBasic_Soft4NetFooterContactDetails);

    //console.log(useDrupalInterfaceTranslation('@items enabled', {'@items': 12}))
    //console.log(useDrupalInterfaceTranslation('soft4cms_all_rights_reserved'));

    // console.log(props.location)

    const { node } = props;
    const className = props.className || false;

    let nodeId = null;
    let productId = null;
    let oWrapperMainClassNode = null;

    if (node) {
      nodeId = node.drupal_internal__nid || null;
      productId = node.drupal_internal__product_id || null;

      oWrapperMainClassNode = {
        [node.internal.type]: true,
        [`drupal_internal__nid-${node.drupal_internal__nid}`]: node.drupal_internal__nid,
        [`drupal_internal__product_id-${node.drupal_internal__product_id}`]: node.drupal_internal__product_id,
        [kebabCase(node.title)]: true,
      }
    }

    // const oClassNamesHtml = classNames({
    //   [`accessibility-high-contrast`]: props.isHighContrastMode,
    //   [`accessibility-font-size-${props.fontSize}`]: true,
    // });

    // const oClassNamesBody = classNames({
    //   [`${useScreenSize()}`]: true,
    // });

    const oWrapperMainClass = classNames({
      'wrapper-main': true,
      [intl.locale]: true,
      hasHeroSlider: context.isHeroSlider,
      // [data.site.siteMetadata.title]: true,
      ...oWrapperMainClassNode,
      [className]: className,
      [`${useScreenSize()}`]: true,
    });

    const ServiceCookieCompliance = loadable(() => import('./s4n/Service/cookieCompliance'));
    const ServiceTawkTo = loadable(() => import('./s4n/Service/tawkTo'));
    // const ServiceFacebookBadge = loadable(() => import('./s4n/Service/facebookBadge'))
    const ServiceScrollTop = loadable(() => import('./s4n/Service/scrollTop'));

    const hasFooterContactForm = [
      1,  // Home
      15, // Products
      16, // Kontakt
      17, // Portfolio
    ].includes(Number(nodeId));

    let ShopCheckoutIframe = null;
    if (`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID) {
      ShopCheckoutIframe = loadable(() => import(`./../components/s4n/Shop/s4n/Checkout/Iframe`), {fallback: <Preloader />})
    }

    return (
    	<React.Fragment>
			{<Helmet
        defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
        // htmlAttributes={{
				// 	class: oClassNamesHtml
				// }}
				// bodyAttributes={{
				// 	class: oClassNamesBody
				// }}
			/>}

			<div 
				// className={cssModule.root}
				className={oWrapperMainClass}
			>
				<Header siteTitle={data.site.siteMetadata.title} />

				<main className="main">
					<S4nLayoutBlock name="main" nodeId={nodeId} productId={productId} hasFooterContactForm={hasFooterContactForm}>
						{children}
					</S4nLayoutBlock>
				</main>

				<Footer hasFooterContactForm={hasFooterContactForm} />

				{true && 
					<React.Fragment>
						<ServiceCookieCompliance />
						<ServiceTawkTo serviceId="serviceId" />
						<ServiceScrollTop />
						{/* <ServiceFacebookBadge serviceId="https://www.facebook.com/soft4net/" serviceName="soft4net.io" /> */}
					</React.Fragment>
				}
			</div>
    	</React.Fragment>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// @see: https://gatsbyguides.com/tutorial/authenticate-users-oauth
// @see: https://www.gatsbyjs.org/tutorial/authentication-tutorial/
// Initialize a new drupalOauth client which we can use to seed the context 
// provider.
const drupalOauthClient = new drupalOauth({
  drupal_root: process.env.SOFT4NET_SOURCE_BASE_URL,
  client_id: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_ID,
  client_secret: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET,
})

const LayoutWrapped = withDrupalOauthProvider(drupalOauthClient, Layout)

const mapStateToProps = state => ({
  fontSize: state.fontSize.fontSize,
  isHighContrastMode: state.highContrast.isHighContrastMode,
})

export default connect(mapStateToProps)(LayoutWrapped);