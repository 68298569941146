// import { Link } from "gatsby"
import React from "react"
import { connect } from 'react-redux'

// s4n
import { useIntl } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import loadable from '@loadable/component'
import Preloader from './s4n/Preloader'
import classNames from 'classnames'
// import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"
// import useBuildTime from './../hooks/useBuildTime'
// ES6
//import Webform from 'hn-react-webform';
import GeneralUtils from './../utils/common'
import Menu from "./s4n/Menu/menu"
import MenuUtils from "./s4n/Menu/utils"

import Logo from './../images/custom/logo.svg'
// import LogoWhite from './../images/custom/logo-white-48.png'
// import { ReactComponent as MapPoland } from './../images/custom/POL_location_map.svg'
// import PromotedFrontPage from './s4n/Promoted/FrontPage'
import Obfuscate from 'react-obfuscate';



const Footer = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { hasFooterContactForm } = props;

    const nodeContact = MenuUtils.getItemByNodeId(5, intl.locale);
    // const buildTime = useBuildTime();
    // const SvgMapPoland = loadable(() => import(`./svg/map-poland`), {fallback: <Preloader />})
    // const FormHeuresisNewsletter = loadable(() => import(`./../components/forms/webform/heuresis_newsletter`), {fallback: <Preloader />})

    const oClassNames = classNames({
        "bg-dark": true,
        "footer-section": hasFooterContactForm,
        // [`alert-${formStatus.class}`]: true,
    });

    const sEmailAddress = `info@soft4net.io`;
    
    return (
        <React.Fragment>
            <footer className={oClassNames}>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h5 className="text-light">
                                    {/* <i className="fas fa-envelope"></i>  */}
                                    E-mail
                                </h5>
                                <p className="text-white paragraph-lg font-secondary">
                                    {/* <a data-encoded={GeneralUtils.rot13(sEmailAddress)} href="mailto:">{GeneralUtils.rot13(sEmailAddress)}</a> */}
                                    <Obfuscate email={sEmailAddress} />
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h5 className="text-light">{ t({ id: `soft4net_footer_phone` }) }</h5>
                                <p className="text-white paragraph-lg font-secondary">
                                    <a href="tel:+48797567617">(+48) 797 567 617</a>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h5 className="text-light">{ t({ id: `soft4net_footer_address` }) }</h5>
                                <p className="text-white paragraph-lg font-secondary" dangerouslySetInnerHTML={{__html: t({ id: `soft4net_contact_address` })}}></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div id="copyright">
                                    <p>
                                        Copyright &copy; {new Date().getFullYear()} { t({ id: "soft4net_all_rights_reserved" }) }
                                    </p>
                                    <p>
                                        <a 
                                            href="https://www.soft4net.pl/" 
                                            title={ t({ id: `soft4net_project_realization_anchor_title` }) } 
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            { t({ id: `soft4net_project_realization` }) } 
                                        </a>
                                    </p>
                                    {/* {`true` === process.env.SOFT4NET_DISPLAY_BUILD_TIME_IN_FOOTER && 
                                        <p className="mt-5">
                                            { t({ id: "soft4net_deploy_build_time" }) }: {GeneralUtils.dateFormat(buildTime, "LLL", intl.locale)}
                                        </p>
                                    } */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 _col-md-6 text-center">
                                <Link to={`/`} title={`Soft4Net`}>
                                    <img 
                                        style={{ width: `5rem` }} 
                                        // src={props.isHighContrastMode ? LogoWhite : Logo} 
                                        src={Logo} 
                                        alt={t({ id: `siteMetadata_author` })} className="logo" />
                                </Link>
                            </div>
                        </div>


                    </div>
                </div>
                {/* <div className="border-top text-center border-dark py-5">
                    <p className="mb-0 text-light">Copyright © 2020 a theme by <a href="themefisher.com">themefisher.com</a></p>
                </div> */}
            </footer>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isHighContrastMode: state.highContrast.isHighContrastMode,
})
export default connect(mapStateToProps)(Footer);