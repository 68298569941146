/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

// import _ from "lodash"
// import React from "react"
// import { globalHistory } from "@reach/router"
// import { useStaticQuery, graphql } from "gatsby"

// s4n
// import Breadcrumb from 'react-bootstrap/Breadcrumb'
import executeQuery from "./../../static/node/page/query"
import CollectionDataUtils from "./../../../utils/Collection/data"



/**
 * @todo: build menus of different types not only "main_menu"
 */
class Page {
    static getAllItems = () => {
        const data = executeQuery()
        return data;
    }

    /**
     * This method we use just for building main menu
     * 
     * @todo: Refactor the loop for performance increase
     */
    static getAllItemsTranslated = (locale) => {
        const data = Page.getAllItems();

        // console.log(data)

        /**
         * Prepare menu items collection without duplications for one locale
         * because of Drupal translation fallback strategy if translation does not exists!
         */

        // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
        const aDataCollectionTranslated = CollectionDataUtils.filterData(data.index.edges, locale);

        return aDataCollectionTranslated;
    }

    /**
     * @todo: Refactor
     * 
     * Right now it takes menu link item or node item!!! 
     * But so far data we request is shared for both
     * 
     * @param {*} nid 
     * @param {*} locale 
     */
    static getItemByNodeId(nid = null, locale)
    {
        const data = Page.getAllItemsTranslated(locale);

        // console.log(data)
        
        let itemNode = null;
        data.forEach(({ node }) => {
            if (!itemNode && node.drupal_internal__nid && nid === node.drupal_internal__nid) { // stop iteration when node found
                itemNode = node;
            }
        });

        return itemNode;
    }
}

export default Page