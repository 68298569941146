import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import MenuUtils from "./../utils"
import Link from "./../../Link"
// import { Breadcrumb } from "react-bootstrap"
import classNames from 'classnames'

const ComponentStateless = (props) => {
    const intl = useIntl();
    // const t = intl.formatMessage;

    const { nid } = props;
    const breadcrumbs = MenuUtils.getBreadcrumbs(nid);

// console.log(nid)

    const oClassNames = classNames({
        [`breadcrumb-item`]: true,
        [`active`]: breadcrumbs.length === 0,
    });

    if (breadcrumbs.length === 0 || nid === Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID)) {
        return null;
    }

    return (
        <React.Fragment>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li key={`breadcrumb-item-${-1}`} className={oClassNames}>
                        <Link to={`/`}>
                            <i className="fas fa-home"></i>
                        </Link>
                    </li>
                    {breadcrumbs.map((nid, index) => {
                        const isLastArrayElement = index === breadcrumbs.length - 1;
                        const oClassNames = classNames({
                            [`breadcrumb-item`]: true,
                            [`active`]: isLastArrayElement,
                        });

                        const nodeMenuItem = MenuUtils.getItemByNodeId(nid);

                        return (
                            <li key={`breadcrumb-item-${nid}`} className={oClassNames}>
                                {isLastArrayElement ? `${nodeMenuItem.title}` : <Link to={nid} />}
                            </li>
                        )
                    })}
                </ol>
            </nav>
        </React.Fragment>
    )
}

export default ComponentStateless