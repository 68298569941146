/**
 * @see: https://lunrjs.com/guides/searching.html
 * By default, Lunr combines multiple terms together in a search with a logical OR. That is, 
 * a search for “foo bar” will match documents that contain “foo” or contain “bar” or contain both.
 * 
 * To simulate a logical AND search of “foo AND bar” mark both terms as required:
 * idx.search("+foo +bar")
 * 
 * Make input width bigger when click on input
 * @see: https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field
 */
import React, { Component } from "react"
import PropTypes from "prop-types"

// s4n
import { injectIntl, Link } from "gatsby-plugin-intl"
import injectData from './../../../utils/ReactComponent/injectData'
import classNames from 'classnames';
// search input
// import { Input } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
// import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';

// Choose search backend
import SearchBackend from './dynamic/backend';
// For static START
// import SearchBackend from './static/backend';
// import useSiteSearchIndex from './../../../model/static/search/query'; // uncomment this file contents!!!
// For static STOP

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.25),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(4),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: fade(theme.palette.common.white, 0.5),
    },
    inputRoot: {
        color: '#fff',
    },
    inputInput: {
        // border: '1px solid #ddd',
        padding: theme.spacing(0.75, 1, 0.25, 4),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
}));



class ComponentStatefull extends Component {

    constructor(props) {
        super(props);

        this.state = {
            closed: true,
            loading: false,
            query: ``,
            results: [],
        }

        const siteSearchIndex = props.useSiteSearchIndex ? props.useSiteSearchIndex.siteSearchIndex : undefined;
        SearchBackend.setSiteSearchIndex(siteSearchIndex);
    }

    search = async () => {
        const { intl } = this.props;

        // const query = event.target.value;
        const { query } = this.state;

        if (!query || query.length < SearchBackend.SEARCH_MIN_QUERY_LENGTH) {
            // console.log('invalid search input')
            return null;
        }

        this.setState({
            loading: true
        });

        const results = await SearchBackend.getResults(query, intl);

        this.setState({
            closed: false,
            loading: false,
            query,
            // Query the index with search string to get an [] of IDs
            // @see: http://elasticlunr.com/docs/index.html
            results,
        })
    }

    handleChange = event => {
        this.setState({
            query: event.target.value,
        });
    }

    handleKeyDown = event => {
        if (event.key === 'Enter') {
            this.search();
        }
    }

    handleClose = () => {
        /**
         * This does not works because this className change is out of scope from React 
         * that is noy aware of this change and does not rerender view!!!
         */
        this.setState({
            closed: true
        });
    }

    render() {
        const { intl } = this.props;
        const t = intl.formatMessage;

        // const classes = useStyles(); // Error: Invalid hook call. Hooks can only be called inside of the body of a function component.
        const classes = this.props.useStyles;

        const resultCount = SearchBackend.getResultsCount();
        const oAgregatedResults = this.state.results;
        let aAgregatedResults = Object.keys(oAgregatedResults);

        aAgregatedResults = [
            ...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,
            ...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,
            ...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,
            ...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,...aAgregatedResults,
        ]

        const searchResultsClass = classNames({
            'search-results': true,
            'has-results': this.state.query && !!resultCount && !this.state.closed,
        });

        return (
            <div className="search-wrapper">
                <div className="search-input">
                    {/* @see: https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field */}
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            {/* <SearchIcon /> */}
                            {this.state.loading ? 
                                <i className="fas fa-circle-notch fa-spin"></i> : <i className="fas fa-search" 
                                    // onClick={this.search}
                                ></i>
                            }
                        </div>
                        <InputBase
                            id={`search_input_name`}
                            placeholder={ t({ id: "soft4net_form_search_placeholder" }) }
                            inputProps={{ 'aria-label': t({ id: "soft4net_form_search_placeholder" }) }}
                            name="name"
                            value={this.state.query}
                            // defaultValue="Bare"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            // onBlur={handleBlur}
                            // helperText={(errors.name && touched.name) && errors.name}
                            // margin="none"
                            // className='search__input'
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            autoComplete="off"
                        />
                    </div>

                    {/* {this.state.loading ? 
                        <i className="fas fa-circle-notch fa-spin"></i> : <i className="fas fa-search" onClick={this.search}></i>
                    } */}
                </div>
                <div className={searchResultsClass}>
                    <div className="close" onClick={this.handleClose}><i className="fas fa-times"></i></div>
                    <div className="wrapper-outer">
                        <div className="wrapper-inner">
                            {resultCount ? 
                                // mapAgregatedResults.forEach((value, nodeTitle) => { // The problem is that forEach does not return anything (i.e it returns undefined). So better use map because it returns an array like this
                                // const aAgregatedNodes = mapAgregatedResults.get(nodeTitle); // === value
                                aAgregatedResults.map(nodeTitle => {
                                    const aAgregatedNodes = oAgregatedResults[nodeTitle];
                                    const hasAgregation = aAgregatedNodes.length === 1 ? false : true;
// console.log(nodeTitle)
// console.log(aAgregatedNodes)
// console.log(hasAgregation)
                                    return (
                                        <React.Fragment>
                                            <div className="result-row">
                                                {!hasAgregation ? 
                                                        <Link 
                                                            key={`search-result-row-${aAgregatedNodes[0].title}`}
                                                            to={aAgregatedNodes[0].path.alias} 
                                                            title={aAgregatedNodes[0].title}
                                                        >{aAgregatedNodes[0].title}</Link>
                                                    : 
                                                        <React.Fragment
                                                            key={`search-result-row-${nodeTitle}`}
                                                        >
                                                            {nodeTitle}
                                                            <ul>
                                                                {aAgregatedNodes.map((nodeIndexFields, index) => {
                                                                    // Harmonogram date
                                                                    let date = nodeIndexFields.date ? nodeIndexFields.date : t({ id: "soft4net_form_search_no_date" });

                                                                    return (
                                                                        <li key={`search-result-row-aggregated-${nodeIndexFields.nid}`}>
                                                                            <Link 
                                                                                to={nodeIndexFields.path.alias} 
                                                                                title={nodeIndexFields.title}
                                                                            >
                                                                                {date && 
                                                                                    <React.Fragment>
                                                                                        { date }
                                                                                    </React.Fragment>
                                                                                }
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            : 
                                <p>{ t({ id: "soft4net_form_search_no_results" }) }</p> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ComponentStatefull.propTypes = {
  searchIndex: PropTypes.object,
}

let ComponentStatefull_Wrapped = injectData(ComponentStatefull, 'useStyles', useStyles);
// For static START
// if (`undefined` !== typeof useSiteSearchIndex) {
//     ComponentStatefull_Wrapped = injectData(ComponentStatefull_Wrapped, 'useSiteSearchIndex', useSiteSearchIndex);
// }
// For static STOP

export default injectIntl(ComponentStatefull_Wrapped);