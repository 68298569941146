import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        # placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        # placeholderImage: file(relativePath: { eq: "custom/heuresis-logo.svg" }) {
        # placeholderImage: file(relativePath: { eq: "custom/custom.jpg" }) {
          placeholderImage: file(relativePath: { eq: "custom/logo.png" }) {
          childImageSharp {
            # fluid(maxWidth: 300) {
            fluid(maxWidth: 350) {
              # ...GatsbyImageSharpFluid
              # @see: https://www.gatsbyjs.org/packages/gatsby-image/
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)

export default Image