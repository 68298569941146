/**
 * Just view/template component, no model/data logic!
 */
import React from "react"
import Img from 'gatsby-image'
import Image from '../../image'

// s4n
import { useIntl, Link } from "gatsby-plugin-intl"
import GeneralUtils from './../../../utils/common'
import kebabCase from "lodash.kebabcase"
// import "./../../assets/sass/templates/node/_blog.scss"



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { node, linkTranslation, bootstrapColClass } = props; // alias t === translate function

    const returnOnlyLink = props.returnOnlyLink || false;
    const anchorClass = props.anchorClass || false;
    const disableImages = props.disableImages || false;
    const disableSummary = props.disableSummary || false;
    const disableButtonMore = props.disableButtonMore || false;

    let img = node?.relationships?.field_image.localFile.childImageSharp.fluid || null;
    if (!img) {
      img = node?.relationships?.field_media_image?.relationships?.field_media_image.localFile.childImageSharp.fluid || null;
    }

    if (returnOnlyLink) {
      return <Link to={ node.path.alias } className={anchorClass}>{ node.title }</Link>
    }

    return (
        <React.Fragment>
          <div key={`blog-item-${node.id}`} className="col-lg-4 col-md-6 mb-4">
            <article className="card shadow">
              {!disableImages && 
                <Link to={node.path.alias} title={node.title} className="image">
                  { img ? <Img fluid={img} className="rounded card-img-top" alt={node.title} /> : <Image /> }
                  {/* date */}
                  {false && <span className="date">
                    {/* {node.created} */}
                    {GeneralUtils.dateFormat(node.created, null, intl.locale)}
                  </span>}
                </Link>
              }
              <div className="card-body">
                <h4 className="card-title">
                  <Link to={node.path.alias} title={node.title} className="text-dark">{node.title}</Link>
                </h4>
                {/* tags */}
                {false && node.relationships.field_tags && !!node.relationships.field_tags.length && <p>
                { t({ id: "soft4net_lists_division_tags" }) }:{` `}
                  {node.relationships.field_tags.map((tag, i) => {
                    const isLastItem = node.relationships.field_tags.length === i + 1;

                    return (
                      <React.Fragment>
                        <Link to={`/${linkTranslation}/tag/${kebabCase(tag.name)}`} title={tag.name} className={``}>{tag.name}</Link>
                        { isLastItem ? `` : `, ` }
                      </React.Fragment>
                    )
                  }
                  )}
                </p>}
                {!disableSummary && node.field_summary && <p className="card-text" dangerouslySetInnerHTML={{ __html: node.field_summary }}></p>}
                {/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value.split(' ').splice(0, 50).join(' ') + '...' : '' }}></div> */}
                {/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value : '' }}></div> */}
                {!disableButtonMore && 
                  <Link to={node.path.alias} title={node.title} className="btn btn-sm btn-primary w-100">
                    { t({ id: "soft4net_more" }) }
                  </Link>
                }
              </div>
            </article>
          </div>
        </React.Fragment>
      )
  }
  
  export default ComponentStateless